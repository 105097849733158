import { Injectable } from '@angular/core';
import { Headers, Http, RequestOptions, Response } from '@angular/http';
import 'rxjs/Rx';
import { Observable } from 'rxjs/Rx';


import * as endpoints from '../../shared/services/api-services/api-endpoints';
import { ApiHandler } from '../../shared/services/api-services/api-handler.service';
import { TokenManager } from '../../shared/services/api-services/token-manager.service';
// tslint:disable-next-line:max-line-length
import { updateStatusURL, updateProfileURL } from '../../shared/services/api-services/api-endpoints';



@Injectable()
export class ProviderService {

  redirectUrl: string;

  constructor(private apiHandler: ApiHandler) { }

  updateStatus(status): Observable<any> {
    // Frame JSON body
    const ReqBody = {
      'status': status ? 'active' : 'away'
    };

    return this.apiHandler.apiPost(updateStatusURL(), ReqBody).map((response: any) => {
      return response;
    });
  }

  updateProfile(formData): Observable<any> {
    // Frame JSON body
    const ReqBody = {
      'name': formData.name
    };

    return this.apiHandler.apiPost(updateProfileURL(), ReqBody).map((response: any) => {
      return response;
    });
  }
}
